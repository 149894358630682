import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";  

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvgyqn8T0uOLqm8QiET9Q4pVmz8m0pdVA",
  authDomain: "otruta-ua.firebaseapp.com",
  projectId: "otruta-ua",
  storageBucket: "otruta-ua.appspot.com",
  messagingSenderId: "264638619007",
  appId: "1:264638619007:web:f227bcc9f09b79e4ec8e4a",
  measurementId: "G-N3FS07862L"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();