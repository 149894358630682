import SpotifyIcon from "../assets/spotify.svg"
import AppleMusicIcon from "../assets/apple-music.svg"
import SoundcloudIcon from "../assets/soundcloudpng.png"
import YoutubeMusicIcon from "../assets/youtube-music.svg"
import TikTokIcon from "../assets/tiktok.svg"

export interface SocialNetwork {
    icon: string,
    caption: string,
    link: string
}

let Spotify: SocialNetwork = {
    icon: SpotifyIcon,
    caption: "Spotify",
    link: "https://open.spotify.com/track/1Fp9i8ty15lvDtKxNkG3Xk?si=RBr18g5RQRSjI9g2AyraJQ"
}

let AppleMusic: SocialNetwork = {
    icon: AppleMusicIcon,
    caption: "Apple",
    link: "https://music.apple.com/ie/artist/%D0%BE%D1%82%D1%80%D1%83%D1%82%D0%B0/1625328159"
}

let YoutubeMusic: SocialNetwork = {
    icon: YoutubeMusicIcon,
    caption: "Youtube",
    link: "https://music.youtube.com/playlist?list=OLAK5uy_kqhbHL4R59EU97EWyvhVt-YzVvvGMmRkg&si=S9uauU1jx_xy3m7J"
}

let Soundcloud: SocialNetwork = {
    icon: SoundcloudIcon,
    caption: "Sound",
    link: "https://soundcloud.com/bprtsk"
}

let TikTok: SocialNetwork = {
    icon: TikTokIcon,
    caption: "TikTok",
    link: "https://www.tiktok.com/@otruta.band"
}

export default [YoutubeMusic,Spotify, AppleMusic, Soundcloud,  /* TikTok */]