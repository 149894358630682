import React from "react";
import PropTypes from "prop-types";

import "./YoutubeEmbed.css"

interface YoutubeEmbedProps {
    embedId: string
}

const YoutubeEmbed = (props: YoutubeEmbedProps) => {
    return (
        <div className="video-responsive" >
            <iframe
                id="youtube"
                src={`https://www.youtube.com/embed/${props.embedId}?autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    )
};

export default YoutubeEmbed;
