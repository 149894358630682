import React, { useEffect } from 'react';
import logo from '../../assets/logo.png';
import transparent from '../../assets/transparent.png';
import YoutubeEmbed from '../youtube/YoutubeEmbed';
import './App.css';

import SocialNetworks from '../../data/SocialNetworks';
import SocialIcon from '../social-icon/SocialIcon';
import minvolo from "../../assets/minvolo.png"

import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from '../..';

import ReactPixel from 'react-facebook-pixel';
ReactPixel.init('1970150356664315');

function App() {
  useEffect(() => {
    logEvent(firebaseAnalytics, "landing");
    ReactPixel.pageView()
  }, []);

  let socialNetworks = SocialNetworks.map((network) => {
    return (<SocialIcon key={network.caption} icon={network.icon} link={network.link} caption={network.caption} />)
  })

  return (
    <div className="content">
      <header className="header">
        <div className="logo-container">
          <div className="logo" />
          <div onError={(event) => (event.target as any).style.display = 'none'} className="hide" />
        </div>
      </header>

      <div className="main">
        <a href="https://linktr.ee/min.volo" className="minvolo">
          <img className="minvolo-image" src={minvolo}></img>
          <div className="minvolo-text">
            <span className="minvolo-title">@min.volo</span>
            <span className="minvolo-caption">Підтримуйте Україну!</span>
          </div>
        </a>
        <YoutubeEmbed embedId="qWWgwVB5OF0" />
        <div className="social">
          {socialNetworks}
        </div>

        <a onClick={() => {
          ReactPixel.track("Instagram")
          window.open("https://www.instagram.com/otruta.ua/", "_self")
        }}
          className="instagram">
          @otruta.ua
        </a>
        <a className="policy" href="https://www.privacypolicygenerator.info/live.php?token=RWBPv2Tokn66zf6bT394A6T40442p9nx">
          Політика приватності
        </a>
      </div>


    </div>
  );
}

export default App;
