import React from "react"
import "./SocialIcon.css"

import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../..";
import ReactPixel from 'react-facebook-pixel';


interface SocialIconProps {
    icon: string,
    caption: string,
    link: string
}

export default (props: SocialIconProps) => {

    let onClick = () => {
        logEvent(firebaseAnalytics, `icon: ${props.caption}`); 
        ReactPixel.track("Platform")
        window.open(props.link, "_self")
    }

    return (
        <div className="icon-body" onClick={() => onClick()}>
            <img className="icon" src={props.icon} />
            <span className="caption">
                {props.caption}
            </span>
        </div>
    )
}